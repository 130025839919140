import { Button, Icon } from "@lobby/core/src/shared";
import { useTranslate } from "@lobby/ocb-intl";
import { Link } from "@tanstack/react-router";
import { clsx } from "clsx";
import { Logo } from "shared/ui";

export function Footer({ className }: { className?: string }) {
  const { $t } = useTranslate();

  return (
    <footer className={clsx(className, "relative")}>
      <div className="mx-auto p-3 lg:max-w-screen-3xl lg:p-5">
        <div className="flex flex-col gap-2 lg:contents">
          <div className="flex-c-sb gap-3 lg:gap-5">
            <Link className="shrink-0" to="/">
              <Logo className="h-9 lg:h-11" />
            </Link>
            <div
              className="flex-center cursor-pointer gap-2.5 whitespace-nowrap font-medium text-16 text-keppel dark:text-java"
              onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
            >
              <span className="hidden">
                {$t({ defaultMessage: "18+" })}
                {$t(
                  {
                    defaultMessage:
                      "Gambling can be addictive. Play responsibly. {themeName} only accept customers over 18 years of age.",
                  },
                  { themeName: import.meta.env.VITE_THEME },
                )}
                {$t({ defaultMessage: "game" })}
                {$t({ defaultMessage: "responsibly" })}
              </span>
              <span>{$t({ defaultMessage: "Back to top" })}</span>
              <Button variant="secondary" fit>
                <Icon className="rotate-90 text-8 lg:text-10" name="arrowLeft" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
