import themeLogoLight from "assets/dark_VEGAS.png";
import themeLogoDark from "assets/light_VEGAS.png";
import { clsx } from "clsx";
import type { HTMLAttributes } from "react";

export function Logo({ className, ...restProps }: HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={clsx("brand-logo", className)} {...restProps}>
      <img
        src={themeLogoDark}
        alt="vegas365 logo"
        className="h-full object-contain hidden dark:block"
      />
      <img src={themeLogoLight} alt="vegas365 logo" className="h-full object-contain dark:hidden" />
    </div>
  );
}
