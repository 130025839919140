import { useTranslate } from "@lobby/ocb-intl";
import { clsx } from "clsx";
import { type HTMLAttributes, useId } from "react";

export function Spinner({ className, ...restProps }: HTMLAttributes<HTMLDivElement>) {
  const { $t } = useTranslate();
  const uniqId1 = useId();
  const uniqId2 = useId();

  return (
    <div className={clsx("w-fit flex-center flex-col", className)} {...restProps}>
      <svg className="w-24 h-auto animate-[spin_1s_linear_infinite]" viewBox="0 0 162 162">
        <linearGradient
          id={uniqId1}
          x1="74.56"
          x2="113.65"
          y1="164.85"
          y2="57.45"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".33" stopColor="#2afdff" />
          <stop offset=".89" stopColor="#2afdff" stopOpacity="0" />
        </linearGradient>

        <linearGradient
          id={uniqId2}
          x1="11.65"
          x2="31.33"
          y1="136.34"
          y2="24.76"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset=".08" stopColor="#2afdff" />
          <stop offset=".63" stopColor="#ff476f" />
        </linearGradient>
        <g>
          <path
            fill={`url(#${uniqId1})`}
            d="M153.5 72.5A8.5 8.5 0 0 0 145 81c0 35.29-28.71 64-64 64a63.8 63.8 0 0 1-46.42-20.01L22.56 137A80.75 80.75 0 0 0 81 162c44.66 0 81-36.34 81-81 0-4.7-3.8-8.5-8.5-8.5z"
          />
          <path
            fill={`url(#${uniqId2})`}
            d="M36.05 35.5 24.05 23.47A80.75 80.75 0 0 0 0 81c0 22.48 9.2 42.84 24.04 57.52l12.02-12.01A63.8 63.8 0 0 1 17 81a63.8 63.8 0 0 1 19.05-45.5z"
          />
          <path
            fill="#FF476F"
            d="M120.02 10A81.09 81.09 0 0 0 81 0a80.75 80.75 0 0 0-58.44 25L34.58 37A63.81 63.81 0 0 1 81 17a64.03 64.03 0 0 1 30.82 7.9 8.5 8.5 0 1 0 8.2-14.9z"
          />
        </g>
      </svg>
      <div className="mt-1 font-medium">{$t({ defaultMessage: "Loading..." })}</div>
    </div>
  );
}
